import { useLocalEnd, useLocalStart, useTableOptions } from '@/composables'
import { fetchOrderCheckouts } from '@api/order'
import { computed, ref, watch } from '@vue/composition-api'
import { debounce } from 'lodash'

const cached = []

export default function useCheckoutList() {
  const invoiceListTable = ref([])

  const moreFilters = ref(false)

  // Table Handlers
  const tableColumns = computed(() => {
    const columns = [
      {
        text: 'Receipt No.',
        align: 'start',
        value: 'id',
      },
      { text: 'User', value: 'user', sortable: false },
      { text: 'DIST.', value: 'distributor_id' },
      { text: 'PAID', value: 'total' },
      { text: 'CREDITS', value: 'credits_total' },
      { text: 'DATE', value: 'created_at' },
      { text: 'STATUS', value: 'status' },
      { text: 'NATURE', value: 'nature', sortable: false },
    ]

    if (moreFilters.value) {
      columns.push({ text: 'Refferal Code', value: 'referral_code' })
    }

    columns.push({
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false,
    })

    return columns
  })

  const options = ref(
    cached[0] || {
      sortBy: ['id'],
      sortDesc: [true],
      page: 1,
    },
  )
  const searchQuery = ref(cached[1] || '')
  const emailQuery = ref(cached[2] || null)
  const statusFilter = ref(cached[3] || null)
  const referralCodeFilter = ref(cached[4] || null)
  const distributorIdFilter = ref(cached[5] || null)
  const merchantIdFilter = ref(cached[6] || null)
  const createdAtFromFilter = ref(cached[7] || null)
  const createdAtToFilter = ref(cached[8] || null)
  const totalInvoiceListTable = ref(0)
  const loading = ref(false)
  const selectedTableData = ref([])

  const loadOrderCheckouts = () =>
    fetchOrderCheckouts(
      useTableOptions(options.value, {
        search: searchQuery.value || undefined,
        email: emailQuery.value || undefined,

        // options: options.value,
        status: statusFilter.value || undefined,
        referral_code: referralCodeFilter.value || undefined,
        distributorId: distributorIdFilter.value || undefined,
        merchantId: merchantIdFilter.value || undefined,
        created_at_from: useLocalStart(createdAtFromFilter.value, 'day'),
        created_at_to: useLocalEnd(createdAtToFilter.value, 'day'),
      }),
    )
      .then(({ data }) => {
        const { records, pagination } = data.data
        invoiceListTable.value = records
        totalInvoiceListTable.value = pagination.total
        loading.value = false
      })
      .catch(error => {
        console.log(error)
      })

  const cachable = [
    options,
    searchQuery,
    emailQuery,
    statusFilter,
    referralCodeFilter,
    distributorIdFilter,
    merchantIdFilter,
    createdAtFromFilter,
    createdAtToFilter,
  ]

  const cache = () => {
    cachable.forEach((variable, index) => {
      cached[index] = variable.value
    })
  }

  watch(
    cachable,
    debounce(() => {
      const newPage = options.value.page

      const hasDiff = cachable.find((variable, index) => index > 0 && cached[index] !== variable.value)

      if (newPage >= 2 && hasDiff) {
        options.value.page = 1
      }

      loading.value = true
      selectedTableData.value = []
      loadOrderCheckouts()
      cache()
    }, 300),
  )

  return {
    tableColumns,
    searchQuery,
    emailQuery,
    statusFilter,
    referralCodeFilter,
    distributorIdFilter,
    merchantIdFilter,
    createdAtFromFilter,
    createdAtToFilter,
    options,
    invoiceListTable,
    totalInvoiceListTable,
    moreFilters,
    loading,
    selectedTableData,
    loadOrderCheckouts,
  }
}
