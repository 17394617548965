<template>
  <v-card id="invoice-list">
    <!-- search -->
    <v-card-text class="pb-0">
      <div class="d-flex align-center flex-wrap">
        <div class="d-flex align-center pb-5">
          <!-- actions -->
          <v-select
            v-model="selectedAction"
            label="Actions"
            single-line
            outlined
            dense
            :items="actions"
            hide-details
            :disabled="Boolean(!selectedTableData.length)"
            class="invoice-list-actions me-3"
          ></v-select>
        </div>

        <v-spacer></v-spacer>

        <div class="d-flex align-center pb-5">
          <v-text-field
            v-model="searchQuery"
            single-line
            dense
            outlined
            hide-details
            placeholder="Search: receipt no. / ref no."
            class="invoice-list-search me-3"
          ></v-text-field>

          <v-text-field
            v-model="emailQuery"
            single-line
            dense
            outlined
            hide-details
            placeholder="User email"
            class="invoice-list-search me-3"
          ></v-text-field>

          <v-select
            v-model="statusFilter"
            :items="statusOptions"
            single-line
            outlined
            dense
            hide-details
            clearable
            placeholder="Select Status"
            class="invoice-list-status"
          ></v-select>

          <a
            class="ml-2"
            @click="moreFilters = !moreFilters"
          >+ Filters</a>
        </div>
      </div>

      <hr
        v-if="moreFilters"
        class="mt-2 mb-4"
      >

      <div
        v-if="moreFilters"
        class="d-flex align-center flex-wrap"
      >
        <div class="d-flex align-center pb-5">
          <v-text-field
            v-model="referralCodeFilter"
            single-line
            dense
            outlined
            hide-details
            placeholder="Referral Code (AXA)"
            class="invoice-list-search me-3"
          ></v-text-field>

          <v-autocomplete
            v-model="distributorIdFilter"
            :items="distributorOptions"
            single-line
            outlined
            dense
            hide-details
            clearable
            item-text="label"
            item-value="value"
            placeholder="Distributor"
            class="invoice-list-status me-3"
          ></v-autocomplete>

          <v-autocomplete
            v-model="merchantIdFilter"
            :items="merchantOptions"
            single-line
            outlined
            dense
            hide-details
            clearable
            item-text="label"
            item-value="value"
            placeholder="Merchant"
            class="invoice-list-status me-3"
          ></v-autocomplete>

          <v-menu
            ref="from"
            v-model="menu1"
            :close-on-content-click="false"
            :nudge-right="40"
            :return-value.sync="createdAtFromFilter"
            lazy
            transition="scale-transition"
            offset-y
            full-width
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="createdAtFromFilter"
                label="From"
                readonly
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="createdAtFromFilter"
              no-title
              scrollable
            >
              <v-btn
                color="primary"
                @click="$refs.from.save(createdAtFromFilter)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>

          <v-menu
            ref="to"
            v-model="menu2"
            :close-on-content-click="false"
            :nudge-right="40"
            :return-value.sync="createdAtToFilter"
            lazy
            transition="scale-transition"
            offset-y
            full-width
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="createdAtToFilter"
                label="To"
                readonly
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="createdAtToFilter"
              no-title
              scrollable
            >
              <v-btn
                color="primary"
                @click="$refs.to.save(createdAtToFilter)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </div>
      </div>
    </v-card-text>

    <!-- table -->
    <v-data-table
      v-model="selectedTableData"
      :headers="tableColumns"
      :items="invoiceListTable"
      :options.sync="options"
      :server-items-length="totalInvoiceListTable"
      :loading="loading"
      class="text-no-wrap"
    >
      <!-- id -->
      <template #[`item.id`]="{item}">
        <router-link
          class="font-weight-medium text-decoration-none"
          :to="{ name: 'checkout-view', params: { id: item.id } }"
        >
          {{ item.receipt_number || 'n/a' }}
        </router-link>
        <small v-show="searchQuery && item.checkout_number.includes(searchQuery)">
          ref no: {{ item.checkout_number }}
        </small>
        <small v-show="searchQuery && item.custom_id.includes(searchQuery)">
          custom id: {{ item.custom_id }}
        </small>
      </template>

      <!-- user -->
      <template #[`item.user`]="{item}">
        <router-link
          class="font-weight-medium text-decoration-none"
          :to="{ name: 'channel-user-view', params: { id: item.user_id } }"
        >
          {{ item.user.email }}
        </router-link>
      </template>

      <!-- user -->
      <template #[`item.distributor_id`]="{item}">
        {{ item.user.distributor.slug }}
      </template>

      <!-- total -->
      <template #[`item.total`]="{item}">
        {{ item.total | localPrice({ currency: item.currency }) }}
      </template>

      <!-- credits_total -->
      <template #[`item.credits_total`]="{item}">
        {{ item.credits_total | localPrice({ currency: item.currency }) }}
      </template>

      <!-- Status -->
      <template #[`item.status`]="{item}">
        <template>
          <v-chip
            small
            :color="statusColor(item)"
            :class="`v-chip-light-bg ${statusColor(item)}--text font-weight-semibold`"
          >
            {{ displayStatusText(item) }}
          </v-chip>
          <span
            v-if="item.status !== 'payment_succeeded'"
          > - {{ item.status }}</span>
        </template>
      </template>

      <!-- Nature -->
      <template #[`item.nature`]="{item}">
        <nature-icon
          v-for="order in item.orders"
          :key="order.uuid"
          :nature="order.nature"
        ></nature-icon>
      </template>

      <!-- date -->
      <template #[`item.created_at`]="{item}">
        <span class="text-no-wrap">{{ formatDate(item.created_at) }}</span>
      </template>

      <!-- actions -->
      <template #[`item.actions`]="{item}">
        <div class="d-flex align-center justify-center">
          <!-- view  -->
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                small
                v-bind="attrs"
                :to="{ name: 'checkout-view', params: { id: item.id } }"
                v-on="on"
              >
                <v-icon size="18">
                  {{ icons.mdiEyeOutline }}
                </v-icon>
              </v-btn>
            </template>
            <span>View Checkout</span>
          </v-tooltip>

          <!-- Receipt -->
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                small
                v-bind="attrs"
                :to="{ name: 'checkout-receipt-preview', params: { id: item.id } }"
                v-on="on"
              >
                <v-icon size="18">
                  {{ icons.mdiReceipt }}
                </v-icon>
              </v-btn>
            </template>
            <span>View Receipt</span>
          </v-tooltip>

          <!-- dropdown -->
          <!-- <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon size="18">
                  {{ icons.mdiDotsVertical }}
                </v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                v-for="(option, i) in actionOptions"
                :key="i"
                href="javascript:void(0)"
              >
                <v-list-item-title>
                  <v-icon
                    size="18"
                    class="me-2"
                  >
                    {{ option.icon }}
                  </v-icon>
                  <span>{{ option.title }}</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu> -->
        </div>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { useDistributor } from '@/composables'
import {
  mdiDeleteOutline, mdiDotsVertical, mdiEyeOutline, mdiPlus, mdiReceipt, mdiTrendingUp,
} from '@mdi/js'

import { t } from '@/plugins/i18n'
import {
  displayStatusText, formatDate, localPrice,
  statusColor,
} from '@core/utils/filter'
import { ref } from '@vue/composition-api'

// composition function
import { NatureIcon } from '@/components'
import useMerchantsList from '@/modules/merchant/views/useMerchantsList'
import useCheckoutList from './useCheckoutList'

export default {
  filters: { localPrice },

  components: { NatureIcon },

  setup() {
    const {
      invoiceListTable, searchQuery, emailQuery, statusFilter, distributorIdFilter, merchantIdFilter, createdAtFromFilter, createdAtToFilter, moreFilters, referralCodeFilter, tableColumns, options, totalInvoiceListTable, loading, selectedTableData,
    } = useCheckoutList()

    const { distributorOptions, fetchDistributors } = useDistributor()
    const { merchantOptions, fetchMerchants } = useMerchantsList()
    fetchDistributors({ take: 999 })
    fetchMerchants({ take: 999 })

    const statusOptions = ref(['wait_for_payment', 'expired', 'cancelled', 'payment_succeeded'])

    const actions = ['Delete', 'Edit', 'Send']

    const selectedAction = ref('')
    const menu1 = ref(false)
    const menu2 = ref(false)

    const actionOptions = [
      // { title: 'Download', icon: mdiDownloadOutline },
      // { title: 'Edit', icon: mdiPencilOutline },
      // { title: 'Delete', icon: mdiDeleteOutline },
      // { title: 'Duplicate', icon: mdiContentCopy },
    ]

    const checkType = data => {
      if (typeof data === 'number') return 'number'
      if (typeof data === 'string') return 'string'

      return 0
    }

    return {
      searchQuery,
      emailQuery,
      statusOptions,
      statusFilter,
      referralCodeFilter,
      distributorIdFilter,
      merchantIdFilter,
      createdAtFromFilter,
      createdAtToFilter,
      distributorOptions,
      merchantOptions,

      menu1,
      menu2,

      tableColumns,
      options,
      totalInvoiceListTable,
      invoiceListTable,
      loading,
      actions,
      selectedTableData,
      moreFilters,
      actionOptions,
      selectedAction,

      checkType,
      formatDate,

      t,
      displayStatusText,
      statusColor,

      icons: {
        mdiTrendingUp,
        mdiPlus,
        mdiDeleteOutline,
        mdiDotsVertical,
        mdiEyeOutline,
        mdiReceipt,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
#invoice-list {
  .invoice-list-actions {
    max-width: 7.81rem;
  }
  .invoice-list-search {
    width: 12.625rem;
  }
  .invoice-list-status {
    max-width: 11.3rem;
  }
}
</style>
@/modules/merchant/merchant/useMerchantsList
